<template>
  <div class="container-fluid mt-5 trending_debates" v-if="UpcomingEvents.length > 0">
    <h2 class="heading mt-3">Upcomming Events</h2>
    <hr />
     <div class="row">
      <div>
        <div class="row">
          <div
            class="col-xs-12 col-sm-6"
            v-for="(event, key) in UpcomingEvents"
            :key="key"
          >
          <div class="row">
            <div class="col-md-6">
               <a  href="#">
                <img :src="`${appUrl()}${event.captionImage}`" class="img-fluid" style='height: 100%; width: 100%; object-fit: cover' />
              </a>
            </div>
            <div class="col-md-6">
              <h4> <b>{{ event.title }}</b></h4>
              <p class="">
                  {{ event.body && event.body.slice(0, 200) }} ... <a href="#">read more</a>
                </p>
                <span>
                  <b>Date: {{ event.date | FromNow }}</b>
                </span><br>
                <span>
                  <b class="text-danger">{{ event.location }}</b>
                </span> <br>
                <span><b>Languages:{{ event.languages }}</b></span>
                
            </div>
            
          </div>
          <div class="row mt-3 mb-5 d-flex justify-content-end" v-if="currentUser.individual && currentUser.individual.is_blog_editor | currentUser.individual.is_superadmin">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6">
                   <button
                      data-toggle="modal"
                      data-target="#updateModal"
                      @click="getSingleEvent(event, key)"
                      class="btn btn-sm btn-primary"
                    >
                      Edit
                    </button>
                </div>
                <div class="col-md-6">
                   <button
                      data-toggle="modal"
                      @click="getResourceID(event.id, key)"
                      data-target="#PastEvent"
                      class="btn btn-sm btn-danger"
                    >
                      Delete
                    </button>
                </div>
              </div>
            </div>
          </div>
            <!-- Delete Past Event -->
            <div
              class="modal fade"
              id="PastEvent"
              tabindex="-1"
              role="dialog"
              aria-labelledby="currentEventLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="currentEventLabel">
                      Delete Current Event
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    Are you sure you want to do this ?
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger"
                      @click="deleteEvent()"
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 
    </div>
    

     <!-- Delete Current Event -->
        <div
          class="modal fade"
          id="currentEvent"
          tabindex="-1"
          role="dialog"
          aria-labelledby="currentEventLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="currentEventLabel">
                  Delete Current Event
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">Are you sure you want to do this ?</div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-danger"
                  @click="deleteEvent"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>

  <!-- edit Modal -->
    <div id="updateModal" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title text-info">Edit</h4>
            <button
              type="button"
              data-dismiss="modal"
              class="close"
              aria-hidden="true"
            >
              &times;
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="updateEvent">
              <div class="form-group">
                <label for="name">Title</label>
                <input
                  type="text"
                  v-model="formData.title"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="email">Location</label>
                <input
                  type="text"
                  v-model="formData.location"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="phone">Date</label>
                <input
                  type="date"
                  v-model="formData.date"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="exampleFormControlFile1">Caption Image</label>
                <div class="base-image-input" @click="chooseImage">
                  <img :src="imageUrl" class="preview_img" id="preview_image">
                   <input class="file-input" ref="fileInput" type="file" @change="handleCaptionImage">
                </div>
               
                <div class="alert alert-danger" v-if="captionError">
                  <p>{{ captionError }}</p>
                </div>
              </div>

              <div class="form-group">
                <label for="sourceUrl">Languages </label>
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="formData.languages"
                />
              </div>

              <div class="form-group">
                <label for="body">Body</label>
                <textarea
                  class="form-control"
                  v-model="formData.body"
                  rows="5"
                ></textarea>
              </div>
              <div class="modal-footer">
                <input
                  type="button"
                  class="btn btn-info"
                  data-dismiss="modal"
                  value="cancel"
                />
                <input type="submit"  class="btn btn-warning" value="update" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import url from "../../helpers/url";
import Storage from "../../helpers/storage";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      token: "",
      id: "",
      key: "",
      UpcomingEvents: [],
      captionError: "",
      success: "",
      error: "",
       imageUrl:"",
      loading: false,
       formData: {
        title: "",
        body: "",
        location: "",
        languages: "",
        captionImage: "",
        date: "",
      },
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  methods: {
    appUrl() {
      return url();
    },

     chooseImage(){
      this.$refs.fileInput.click()
    },

      getSingleEvent(data, key) {
      this.success = null;
      this.error = null;
      this.loading = false;

      this.id = data.id;
      this.key = key;
      this.formData.title = data.title;
      this.formData.body = data.body;
      this.formData.location = data.location;
      this.formData.languages = data.languages;
      this.formData.captionImage = data.captionImage;
      this.formData.date = data.date ? moment(data.date).format('').split("T")[0]:''
      this.imageUrl = `${url()}${data.captionImage}`
    },

    getResourceID(id, key) {
      this.success = null;
      this.error = null;
      this.loading = false;

      this.id = id;
      this.key = key;
    },

     handleCaptionImage(e) {
     let files = e.target.files;

     this.formData.captionImage = files

     if(!this.formData.captionImage){
       this.captionError = "Caption image is required"
     }

     if(files && files[0]){
       var reader = new FileReader();
       reader.onload = function(e){
         document.getElementById('preview_image').src = e.target.result;
       }

       reader.readAsDataURL(files[0]);
     }
    },

    fetchUpcomingEvents() {
      axios.get(`${url()}/api/blog/fetch-countdown-event`).then((response) => {
        console.log(response.data.EventCountdown);
        this.UpcomingEvents = response.data.EventCountdown;
      });
    },

    async updateEvent() {

      // console.log("You clicked me ")
      try {
        this.success = null;
        this.error = null;
        this.loading = true;

        let newFormData = new FormData();

        if (this.formData.captionImage.length < 1) {
          this.captionError = "featured Image required.";
          thi.loading = false;
          return;
        } else {
          this.captionError = "";
        }

        if(this.formData.date){
          let formartDate = new Date(this.formData.date).toISOString()
          newFormData.append('date',formartDate)
        }else{
          newFormData.append("date","")
        }

        // let FormatDate = new Date(this.formData.date).toISOString().split('T')[0]

        // console.log(FormatDate)

        newFormData.append("title", this.formData.title);
        newFormData.append("location", this.formData.location);
        newFormData.append("languages", this.formData.languages);
        // newFormData.append("date", FormatDate );
        newFormData.append("body", this.formData.body);
        newFormData.append("captionImage", this.formData.captionImage[0]);
    
        const response = await axios.patch(
          `${url()}/api/blog/update-current-event/${this.id}/`, newFormData,
          {
            headers: {
              Authorization: this.token,
            },
          }
        );

        this.CurrentEvents[this.key] = response.data;
        this.fetchUpcomingEvents()
        this.$toasted.success('event updated successfully')
      } catch (err) {
        this.loading = false;
        if (err?.response?.data == undefined) {
          this.error = "Network Error. Try again.";
        }
      }
    },

    async deleteEvent() {
      try {
        this.success = null;
        this.error = null;
        this.loading = true;
        const response = await axios.delete(
          `${url()}/api/blog/delete-current-event/${this.id}/`,
          {
            headers: {
              Authorization: this.token,
            },
          }
        );
        this.success = "Past Event deleted successfully";
        this.UpcomingEvents.splice(this.key, 1);
        this.fetchUpcomingEvents();
      } catch (err) {
        this.loading = false;
        if (err?.response?.data == undefined) {
          this.error = "Network Error. Try again.";
        }
      }
    },
  },
  mounted() {
    this.token = Storage.get("token");
    this.fetchUpcomingEvents();
  },
};
</script>

<style  scoped>
.eventBtn {
  background-color: rgb(235, 41, 41);
  float: right;
  border: none;
  outline: none;
  color: #fff;
  font-size: 1.2em;
  padding: 0.6em;
  transition: all 0.5s;
}
.eventBtn:hover {
  outline: none;
  border: none;
  opacity: 0.7;
}
.wrapper {
  display: table;
  height: 100%;
  width: 100%;
}

.container-fostrap {
  display: table-cell;
  padding: 1em;
  text-align: center;
  vertical-align: middle;
}
.fostrap-logo {
  width: 100px;
  margin-bottom: 15px;
}
h1.heading {
  color: #fff;
  font-size: 1.15em;
  font-weight: 900;
  margin: 0 0 0.5em;
  color: #505050;
}
@media (min-width: 450px) {
  h1.heading {
    font-size: 3.55em;
  }
}
@media (min-width: 760px) {
  h1.heading {
    font-size: 3.05em;
  }
}
@media (min-width: 900px) {
  h1.heading {
    font-size: 3.25em;
    margin: 0 0 0.3em;
  }
}
.card {
  display: block;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.25s;
}
.card:hover {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.img-card {
  width: 100%;
  height: 200px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  display: block;
  overflow: hidden;
}
.img-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: all 0.25s ease;
}
.card-content {
  padding: 15px;
  text-align: left;
}
.card-title {
  margin-top: 0px;
  font-weight: 700;
  font-size: 1.65em;
}
.card-title a {
  color: #000;
  text-decoration: none !important;
}
.card-read-more {
  border-top: 1px solid #d4d4d4;
}
.card-read-more a {
  text-decoration: none !important;
  padding: 10px;
  font-weight: 600;
  text-transform: uppercase;
}
</style>